module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Grow Your Soil","short_name":"Grow Your Soil","start_url":"/","icon":"src/images/favicon-rounded-512x512.png","cache_busting_mode":"none","background_color":"#ffffff","theme_color":"#e4ff58","display":"standalone","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../../../packages/gatsby-plugin-netlify-ab/gatsby-browser.js'),
      options: {"plugins":[],"branch":"master"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
