import React from 'react';

import 'gatsby-theme-kalanso-ui/src/styles/global.scss';
import 'gatsby-theme-kalanso-ui/src/styles/font-family.scss';

import { AppStateProvider } from './src/components/AppStateProvider';

export const wrapRootElement = ({ element }) => {
  return <AppStateProvider children={element} />;
};

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }
};
