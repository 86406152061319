// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-access-js": () => import("./../../../src/pages/access.js" /* webpackChunkName: "component---src-pages-access-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insiders-guide-download-js": () => import("./../../../src/pages/insiders-guide/download.js" /* webpackChunkName: "component---src-pages-insiders-guide-download-js" */),
  "component---src-pages-insiders-guide-js": () => import("./../../../src/pages/insiders-guide.js" /* webpackChunkName: "component---src-pages-insiders-guide-js" */)
}

