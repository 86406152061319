import React, { useReducer, createContext } from 'react';

const INSIDERS_GUIDE_REQUESTED = 'INSIDERS_GUIDE_REQUESTED';

export const actions = {
  INSIDERS_GUIDE_REQUESTED
}

const initialAppState = {
  insidersGuideRequested: false
};

export const AppStateContext = createContext(initialAppState);

function appStateReducer(state, action) {
  if (action.type === INSIDERS_GUIDE_REQUESTED) {
    return {
      ...state,
      insidersGuideRequested: true,
    };
  }

  return state;
}

export const AppStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appStateReducer, initialAppState);

  return (
    <AppStateContext.Provider value={[state, dispatch]}>
      {children}
    </AppStateContext.Provider>
  );
};
